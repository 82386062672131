const Spin = (props: React.SVGProps<SVGSVGElement> & { circleFill?: string; rotatedElementFill?: string }) => (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle
            cx={10}
            cy={10}
            r={9}
            stroke={props.rotatedElementFill ? props.rotatedElementFill : "#B2E8CE"}
            strokeWidth={2}
        />
        <mask id="a" fill="#fff">
            <path d="M20 10a10 10 0 1 0-15.134 8.581l.95-1.586A8.152 8.152 0 1 1 18.15 10H20Z" />
        </mask>
        <path
            d="M20 10a10 10 0 1 0-15.134 8.581l.95-1.586A8.152 8.152 0 1 1 18.15 10H20Z"
            stroke={props.circleFill ? props.circleFill : "#fff"}
            strokeWidth={4}
            mask="url(#a)"
        />
    </svg>
)
export default Spin
