import React from "react"
import { IMPERSONATOR_AUTH_TOKEN_KEY } from "@/utils/auth"
import { unpackQueryArray } from "@/utils/unpackQueryArray"
import _Link, { LinkProps } from "next/link"
import { useRouter } from "next/router"

export const Link = (p: React.PropsWithChildren<LinkProps>) => {
    const { query } = useRouter()

    const impersonatorToken = unpackQueryArray(query[IMPERSONATOR_AUTH_TOKEN_KEY])
    const additionalQueryParams = impersonatorToken ? { impersonatorToken } : {}

    const href =
        typeof p.href === "string"
            ? p.href
            : {
                  ...p.href,
                  query:
                      typeof p.href.query === "string" ? p.href.query : { ...p.href.query, ...additionalQueryParams },
              }

    return <_Link {...p} href={href} />
}
